@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
html,body,.App,#root {
  width: 100%;
  height: 100%;
  display: inline-block;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  background: #fff;
  color: #000;
}
body * {
  box-sizing: border-box;
}
h1 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px;
  letter-spacing: -0.9px;
  text-align: center;

}

h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.banner {
  height: 299px;
  margin-bottom: 50px;
}

ul {
  list-style: none;
  padding-left: 0;
}
ul li {
  font-size: 20px;
  line-height: 23px;
  font-weight: 300;
}
ul li + li {
  margin-top: 12px;
}
.details-wrap h2,
.terms-and-conditions h2 {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}
.details-wrap p,
.terms-and-conditions p,
.terms-and-conditions h3 {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}
h3 {
  font-weight: 500;
}
@media (max-width: 428px) {
  h1 {
    font-size: calc(17px + (24 - 17) * (100vw - 320px)/(428 - 320));
    line-height: calc(21px + (28 - 21) * (100vw - 320px)/(428 - 320));
  }
  h2 {
    font-size: calc(17px + (24 - 17) * (100vw - 320px)/(428 - 320));
    line-height: calc(21px + (28 - 21) * (100vw - 320px)/(428 - 320));
  }
  .banner {
    height: calc(240px + (299 - 240) * (100vw - 320px)/(428 - 320));
    margin-bottom: calc(30px + (50 - 30) * (100vw - 320px)/(428 - 320));
  }
  ul li {
    font-size: calc(14px + (20 - 14) * (100vw - 320px)/(428 - 320));
    line-height: calc(17px + (23 - 17) * (100vw - 320px)/(428 - 320));
  }
  ul li + li {
    margin-top: calc(8px + (12 - 8) * (100vw - 320px)/(428 - 320));
  }
  .details-wrap img[alt="banner"] {
    height: calc(225px + (341 - 225) * (100vw - 320px)/(428 - 320)) !important;
  }
}


p {
  margin-top: 0;
  margin-bottom: 27px;
}
.text-right {
  text-align: right;
}
.toggle-button * {
  color: #000 !important;
  text-transform: capitalize !important;
}
p.position-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
}
.edit-button {
  width: 15px;
  height: 15px;
  background-color: transparent;
  padding: 0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.08029 1.88356L1.91001 6.46421C1.75254 6.63816 1.60016 6.98078 1.56968 7.21798L1.38174 8.92584C1.31571 9.54257 1.74239 9.96426 2.33161 9.85884L3.96721 9.56892C4.19579 9.52676 4.5158 9.35281 4.67326 9.17359L8.84354 4.59294C9.56483 3.80227 9.88992 2.9009 8.76734 1.79923C7.64985 0.708095 6.80158 1.09289 6.08029 1.88356Z' stroke='%23606060' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #606060;
  margin-left: 20px;
}
button.refresh {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='40' height='40' fill='white'/%3E%3Cpath d='M11.2207 16.9976C11.6719 15.7202 12.4043 14.519 13.4355 13.4937C17.0977 9.83154 23.0332 9.83154 26.6953 13.4937L27.6973 14.5015H24.75C23.7129 14.5015 22.875 15.3394 22.875 16.3765C22.875 17.4136 23.7129 18.2515 24.75 18.2515H32.2207H32.2441C33.2812 18.2515 34.1191 17.4136 34.1191 16.3765V8.87646C34.1191 7.83936 33.2812 7.00146 32.2441 7.00146C31.207 7.00146 30.3691 7.83936 30.3691 8.87646V11.8765L29.3437 10.8452C24.2168 5.71826 15.9082 5.71826 10.7812 10.8452C9.35156 12.2749 8.32031 13.9565 7.6875 15.7554C7.3418 16.7339 7.85742 17.8003 8.83008 18.146C9.80273 18.4917 10.875 17.9761 11.2207 17.0034V16.9976ZM7.34766 22.0776C7.05469 22.1655 6.77344 22.3237 6.54492 22.5581C6.31055 22.7925 6.15234 23.0737 6.07031 23.3784C6.05273 23.4487 6.03516 23.5249 6.02344 23.6011C6.00586 23.7007 6 23.8003 6 23.8999V31.3765C6 32.4136 6.83789 33.2515 7.875 33.2515C8.91211 33.2515 9.75 32.4136 9.75 31.3765V28.3823L10.7812 29.4077C15.9082 34.5288 24.2168 34.5288 29.3379 29.4077C30.7676 27.978 31.8047 26.2964 32.4375 24.5034C32.7832 23.5249 32.2676 22.4585 31.2949 22.1128C30.3223 21.7671 29.25 22.2827 28.9043 23.2554C28.4531 24.5327 27.7207 25.7339 26.6895 26.7593C23.0273 30.4214 17.0918 30.4214 13.4297 26.7593L13.4238 26.7534L12.4219 25.7515H15.375C16.4121 25.7515 17.25 24.9136 17.25 23.8765C17.25 22.8394 16.4121 22.0015 15.375 22.0015H7.89844C7.80469 22.0015 7.71094 22.0073 7.61719 22.019C7.52344 22.0308 7.43555 22.0483 7.34766 22.0776Z' fill='black' fill-opacity='0.6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  margin-left: 20px;
  background-position: center;
  background-size: 100%;
  padding: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
}
button.refresh.loading {
  border: none;
  animation: scaleRotateAnimation 3s infinite;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='40' height='40' fill='white'/%3E%3Cpath d='M11.2207 16.9976C11.6719 15.7202 12.4043 14.519 13.4355 13.4937C17.0977 9.83154 23.0332 9.83154 26.6953 13.4937L27.6973 14.5015H24.75C23.7129 14.5015 22.875 15.3394 22.875 16.3765C22.875 17.4136 23.7129 18.2515 24.75 18.2515H32.2207H32.2441C33.2812 18.2515 34.1191 17.4136 34.1191 16.3765V8.87646C34.1191 7.83936 33.2812 7.00146 32.2441 7.00146C31.207 7.00146 30.3691 7.83936 30.3691 8.87646V11.8765L29.3437 10.8452C24.2168 5.71826 15.9082 5.71826 10.7812 10.8452C9.35156 12.2749 8.32031 13.9565 7.6875 15.7554C7.3418 16.7339 7.85742 17.8003 8.83008 18.146C9.80273 18.4917 10.875 17.9761 11.2207 17.0034V16.9976ZM7.34766 22.0776C7.05469 22.1655 6.77344 22.3237 6.54492 22.5581C6.31055 22.7925 6.15234 23.0737 6.07031 23.3784C6.05273 23.4487 6.03516 23.5249 6.02344 23.6011C6.00586 23.7007 6 23.8003 6 23.8999V31.3765C6 32.4136 6.83789 33.2515 7.875 33.2515C8.91211 33.2515 9.75 32.4136 9.75 31.3765V28.3823L10.7812 29.4077C15.9082 34.5288 24.2168 34.5288 29.3379 29.4077C30.7676 27.978 31.8047 26.2964 32.4375 24.5034C32.7832 23.5249 32.2676 22.4585 31.2949 22.1128C30.3223 21.7671 29.25 22.2827 28.9043 23.2554C28.4531 24.5327 27.7207 25.7339 26.6895 26.7593C23.0273 30.4214 17.0918 30.4214 13.4297 26.7593L13.4238 26.7534L12.4219 25.7515H15.375C16.4121 25.7515 17.25 24.9136 17.25 23.8765C17.25 22.8394 16.4121 22.0015 15.375 22.0015H7.89844C7.80469 22.0015 7.71094 22.0073 7.61719 22.019C7.52344 22.0308 7.43555 22.0483 7.34766 22.0776Z' fill='blue' fill-opacity='0.6'/%3E%3C/svg%3E");
}
@keyframes scaleRotateAnimation {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
.react-responsive-modal-modal textarea {
  width: 100%;
  min-width: 280px;
  height: 100px;
  resize: none;
  padding: 10px;
  border-width: 0px 0px 1px 0px;
  margin-bottom: 5px;
  color: rgba(0,0,0,0.6);
}

.react-responsive-modal-modal button {
  color: #9492E1;
  background: #fff;
  box-shadow: none;
  border: 0;
  text-transform: uppercase;
  padding: 10px;
}
.react-responsive-modal-modal button + button {
  margin-left: 20px;
}

.react-responsive-modal-closeButton {
  display: none;
}
.img-coords {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
.img-coords p {
  margin: 2px 0;
  font-size: 12px;
  line-height: 1.2;
  width: 100%;
  display: inline-block;
  clear: both;
  font-weight: 500;
  color: #fff;
  position: relative;
}
.terms-and-conditions h1 {
  text-align: left;
  color: #9492E1;
}
.terms-and-conditions {
  padding-bottom: 100px;
}

button {
  cursor: pointer;
}
#domEl .img-wrap {
  position: relative;
}
#domEl .img-wrap:before{
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: inline-block;
  position: absolute;
  background: rgba(0,0,0,0.6);
  background: linear-gradient(to top, rgba(0,0,0,0.6) 0%, transparent 60%);
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #9492E1;
  border-color: #9492E1 transparent #9492E1 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ripple div {
  position: absolute;
  border: 2px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.isDownloading,.isDownloaded  {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background: #9492E1;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 0;
  right: 50%;
  bottom: 0;
  transform: translate(-50%,0);
  max-width: 428px;
}
.isDownloaded {
  background: white;
}
.text-center {
  text-align: center !important;
}